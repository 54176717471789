import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactusPage from "../components/contactus/index"
const contactus = () => {
    return (
        <Layout>
            <SEO
                title="contactus innoway system"
                desc="Frequently asked questions from innoway system "
                pathname="/contactus/"
                keywords="FAQs,Careers"
            />
            <ContactusPage />
        </Layout>
    )
}

export default contactus
