import React from "react"

const Frequently = () => {
	return (
		<>
			<section className="cad">
				<div className="container pt-4">
					<div className="row">
						<div className="text-center">
							<h4 className="cadservices">Frequently asked questions (FAQs)</h4><br />
						</div>
						<div className="col-12">
							<div className="accordion" id="accordionExample">
								<div className="card1">
									<div className="card-header" id="headingOne">
										<h2 className="mb-0">
											<button className="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
												What is our confidentiality policy?
       										 </button>
										</h2>
									</div>

									<div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
										<div className="card-body">
											We understand our client’s concerns regarding confidentiality. We have no issues signing a non-disclosure agreement (NDA) for projects. All data provided by the client will be kept confidential and will be the property of the client. Our firm and staffs are agree that no information regarding the project will be disclosed to any third party.
      									</div>
									</div>
								</div>
								<div className="card1">
									<div className="card-header" id="headingTwo">
										<h2 className="mb-0">
											<button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
												Where is your office located?
      										  </button>
										</h2>
									</div>
									<div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
										<div className="card-body">
											Coimbatore, India.
      										</div>
									</div>
								</div>
								<div className="card1">
									<div className="card-header" id="headingThree">
										<h2 className="mb-0">
											<button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
												Do you serve to any international customers?
       										 </button>
										</h2>
									</div>
									<div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
										<div className="card-body">
											Yes, we do out source services to Spain, USA.
     									 </div>
									</div>
								</div>
								<div className="card1">
									<div className="card-header" id="headingfour">
										<h2 className="mb-0">
											<button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapsefour" aria-expanded="false" aria-controls="collapsefour">
												How do I get electronic files?
       										 </button>
										</h2>
									</div>
									<div id="collapsefour" className="collapse" aria-labelledby="headingfour" data-parent="#accordionExample">
										<div className="card-body">
											ftp and email are used for data transfer.
    									  </div>
									</div>
								</div>
								<div className="card1">
									<div className="card-header" id="headingfive">
										<h2 className="mb-0">
											<button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapsefive" aria-expanded="false" aria-controls="collapsefive">
												What are the acceptable file formats?
        									</button>
										</h2>
									</div>
									<div id="collapsefive" className="collapse" aria-labelledby="headingfive" data-parent="#accordionExample">
										<div className="card-body">
											Please send image files in .pdf, .tif or .gif format.
     									 </div>
									</div>
								</div>
								<div className="card1">
									<div className="card-header" id="headingsix">
										<h2 className="mb-0">
											<button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapsesix" aria-expanded="false" aria-controls="collapsesix">
												What are your normal working hours?
       										 </button>
										</h2>
									</div>
									<div id="collapsesix" className="collapse" aria-labelledby="headingsix" data-parent="#accordionExample">
										<div className="card-body">
											India office open at
											India time from 9:00 am to 5:00 pm.
											Eastern standard time (US) 6:00 am to 12:00 pm.
     									 </div>
									</div>
								</div>
								<div className="card1">
									<div className="card-header" id="headingseven">
										<h2 className="mb-0">
											<button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseseven" aria-expanded="false" aria-controls="collapseseven">
												What is the preferred means of communication?
       										 </button>
										</h2>
									</div>
									<div id="collapseseven" className="collapse" aria-labelledby="headingseven" data-parent="#accordionExample">
										<div className="card-body">
											Email  (sales@innoway.in)
											Teleconference
											Skype (anand.cbe3)
      									</div>
									</div>
								</div>
								<div className="card1">
									<div className="card-header" id="headingeight">
										<h2 className="mb-0">
											<button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseeight" aria-expanded="false" aria-controls="collapseeight">
												What is the cost?

        									</button>
										</h2>
									</div>
									<div id="collapseeight" className="collapse" aria-labelledby="headingeight" data-parent="#accordionExample">
										<div className="card-body">
											Project based.
											Pilot project is charged free of cost.
											Ballpark price would be USD $12 per hour.
											Long term contract is charged at lower prices, contact us for details.
     									 </div>
									</div>
								</div>
								<div className="card1">
									<div className="card-header" id="headingnine">
										<h2 className="mb-0">
											<button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapsenine" aria-expanded="false" aria-controls="collapsenine">
												What drawing standards are used?

       										 </button>
										</h2>
									</div>
									<div id="collapsenine" className="collapse" aria-labelledby="headingnine" data-parent="#accordionExample">
										<div className="card-body">
											We will use your drawing standards as provided by you.
      									</div>
									</div>
								</div>
								<div className="card1">
									<div className="card-header" id="headingTen">
										<h2 className="mb-0">
											<button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseten" aria-expanded="false" aria-controls="collapseten">
												What is the mode of payment?

        									</button>
										</h2>
									</div>
									<div id="collapseten" className="collapse" aria-labelledby="headingTen" data-parent="#accordionExample">
										<div className="card-body">
											PayPal
											Swift transfer to our bank account.
      									</div>
									</div>
								</div>
								<div className="card1">
									<div className="card-header" id="heading1">
										<h2 className="mb-0">
											<button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapse1" aria-expanded="false" aria-controls="collapse1">
												Can you provide on-site cad technicians?

        									</button>
										</h2>
									</div>
									<div id="collapse1" className="collapse" aria-labelledby="heading1" data-parent="#accordionExample">
										<div className="card-body">
											Yes! If your project requires on-site staffing in USA or Canada we can do that. Contact us regarding rates. Minimum contract period for on-site staffing is 1-year.
      									</div>
									</div>
								</div>
								<div className="card1">
									<div className="card-header" id="heading2">
										<h2 className="mb-0">
											<button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapse2" aria-expanded="false" aria-controls="collapse2">
												Why should I outsource cad services?

        									</button>
										</h2>
									</div>
									<div id="collapse2" className="collapse" aria-labelledby="heading2" data-parent="#accordionExample">
										<div className="card-body">
											Cost Saving 40% to 70%
											Faster turnaround of services.

											Time zone advantages mean ability to work “around the clock”.
											Additional time available for your team to enhance the core business
											No capital investment in case of expansion in terms of manpower
											Reduced costs for office space, computers, software, insurance, employee training, etc.
											Become more competitiveness in a tough economy.
											Improve your client satisfaction with timely deliveries, higher quality services and lower prices.
    									  </div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section><br />
		</>
	)
}

export default Frequently
