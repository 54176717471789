import React from "react"

const address = () => {
  return (
    <>
      <section className="world pt-5">
        <div className="container conatct-forms">
          <div className="row">
            <div className="col-12 col-lg-6">
              <h4>Contact Us</h4>
              <p className="paragraph">Head office: No: 30, Ohm Vinayaka Street, Thanneer Pandal, V. K. Road, Peelamedu, Coimbatore – 641004 India</p>
              <p><a href="mailto:sales@innoway.in">sales@innoway.in</a></p>
              <p className="paragraph">Mobile: +91 890 304 0044<br />Landline: +91 422 2513247</p><br />
              <div className="row">
                <div className="col-12">
                  <div className="static-map">
                    <iframe frameBorder="0" scrolling="no" src="https://maps.google.com/maps?q=Innoway%20Systems%20No%3A30%2C%20Ohm%20Vinayaka%20Street%2C%20Thanneer%20Pandal%2C%20VK%20Rd%2C%20Peelamedu%2C%20Tamil%20Nadu%20641004&amp;t=m&amp;z=10&amp;output=embed&amp;iwloc=near" aria-label="Innoway Systems No:30, Ohm Vinayaka Street, Thanneer Pandal, VK Rd, Peelamedu, Tamil Nadu 641004" title="address" height="300px"></iframe></div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="contact">
                <form className="form-horizontal" action="" method="post">
                  <div className="form-group">
                    <label className="col-md-3 control-label" htmlFor="name">Name</label>
                    <div className="col-md-9">
                      <input id="name" name="name" type="text" placeholder="Your name" className="form-control"></input>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-md-3 control-label" htmlFor="email">Your E-mail</label>
                    <div className="col-md-9">
                      <input id="email" name="email" type="text" placeholder="Your email" className="form-control"></input>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-md-3 control-label" htmlFor="message">Your message</label>
                    <div className="col-md-9">
                      <textarea className="form-control" id="message" name="message" placeholder="Please enter your message here..." rows="5"></textarea>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="col-md-12 text-left">
                      <button type="submit" className="btn btn-danger btn-lg">Submit</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default address
