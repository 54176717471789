import React from "react"
import Slider from "./slider"
import Address from "./address"
import Frequently from "./Frequently"


const ContactusPage = () => {
    return (
        <>

            <Slider />
            <Address />
            <Frequently />

        </>
    )
}

export default ContactusPage
